<template>
  <div>
    <ecat-cropper ref="ecatCropper" @success="successCropImage"/>
  </div>
</template>
<script>
import {imageHelper} from "@/helpers/image-helper";
import ecatCropper from "@/components/ecat-cropper.vue";
import axios from "axios";
import {errorCatcher} from "../../helpers/error-catcher";

export default {
  components: {
    ecatCropper
  },

  data() {
    return {
      languageId: ""
    }
  },

  methods: {
    async successCropImage({file, extension}) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('extension', extension)

      try {
        const {data} = await axios.post(`/cdn/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        try {
          await this.setAderloCashbackImageUrl(data.url)
        } catch (error) {
          return
        }

        this.$emit("success")
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async setAderloCashbackImageUrl(url) {
      try {
        const json = {
          languageId: this.languageId,
          imageUrl: url
        }

        await axios.post(`/language/set-aderlo-cashback-image-url`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        })

      } catch (error) {
        errorCatcher.catchErrors(error)
        throw error;
      }
    },

    sendAderloCashback(languageId, input) {
      this.languageId = languageId

      imageHelper.fetchFromInput(input).then((result) => {
        if (!result) {
          return;
        }

        const {dataUrl} = result
        this.$refs.ecatCropper.openModal(dataUrl, "rectangle-stencil", 1600 / 533)
      }).catch((error) => {
        console.error('Error occurred:', error);
      });
    }
  }

}
</script>